.display {
    width: 320px;
    margin: 0 auto;
    text-align:right;
    font-size:32px;
}
.buttonPanel {
    button {
        width: 80px;
        height: 80px;
    }
}
